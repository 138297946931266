<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
    <div>
      <vx-card title="Customer Deposit Allocation">
        <div class="vx-row mt-3">
          <div class="vx-col sm:w-1/2 w-full mb-base">
            <span class="py-2 h5 font-semibold">Customer Deposit Detail</span><hr>
            <div class="vx-row my-6">
              <div class="vx-col sm:w-1/3 w-full">
                  <span>Customer</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full input-readonly" v-model="detail.customer" readonly />
              </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Customer Deposit Code</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.code" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Source Transaction</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.sourceTransaction" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Method</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.method" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Deposit Number</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.depositNumber" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Posting Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.postingDate" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Deposit Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.depositDate" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <label class="vs-input--label">Deposit Value</label>
                    <vs-input class="w-full input-readonly" v-model="detail.depositValue" readonly />
                </div>
                <div class="vx-col sm:w-1/4 w-full">
                    <label class="vs-input--label">Used</label>
                    <vs-input class="w-full input-readonly" v-model="totalUsed" readonly />
                </div>
                <div class="vx-col sm:w-1/4 w-full">
                    <label class="vs-input--label">Allocate</label>
                    <vs-input class="w-full input-readonly" v-model="totalAllocate" readonly />
                </div>
                <div class="vx-col sm:w-1/4 w-full">
                    <label class="vs-input--label">Available</label>
                    <vs-input class="w-full input-readonly" v-model="totalAvailable" readonly />
                </div>
            </div>
            <span class="py-2 h5 font-semibold">Allocate</span><hr>
            <div class="vx-row mb-6">
              <vs-table
                :data="allocate"
                style="width: 100%; margin-left: 2%"
              >
                <template slot="thead">
                  <vs-th></vs-th>
                  <vs-th> Reference </vs-th>
                  <vs-th> Value </vs-th>
                  <vs-th></vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            <vs-button
                                size="small"
                                type="line"
                                @click="removeField(indextr)"
                                icon-pack="feather"
                                icon="icon-trash"
                            ></vs-button>
                        </vs-td>
                        <vs-td>
                            <vs-input class="w-full" v-model="tr.reference"/>
                        </vs-td>
                        <vs-td>
                            <vue-number
                                :customClass="`w-full`"
                                v-model="tr.value"
                                :masked="true"
                            ></vue-number>
                            <!-- <vs-input class="w-full" v-model="tr.value"/> -->
                        </vs-td>
                        <vs-td>
                            <vs-button
                                v-if="indextr == 0"
                                style="margin-right: 5px"
                                size="small"
                                @click="addField(indextr)"
                                icon-pack="feather"
                                icon="icon-plus"
                            ></vs-button>
                        </vs-td>
                    </vs-tr>
                </template>
              </vs-table>
            </div>
            <vs-button
                class="mb-2 mr-2"
                v-on:click="handleSubmit()"
            >
                Save
            </vs-button>
            <vs-button
                class="mb-2 mr-2"
                color="danger"
                type="border"
                v-on:click="navigateBack()"
              >
                Close
              </vs-button>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-base">
            <span class="py-2 h5 font-semibold">Payment Detail</span><hr>
            <div class="vx-row my-6" style="width: 100%">
              <vs-table
                stripe
                :data="dataGet.cashBankLine"
                style="width: 100%; margin-left: 2%"
              >
                <template slot="thead">
                  <vs-th> Payment Code </vs-th>
                  <vs-th> Type </vs-th>
                  <vs-th> Payment Value </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>{{ tr.PaymentCode }}</vs-td>
                        <vs-td>{{ tr.TransactionType }}</vs-td>
                        <vs-td>{{ formatPrice(tr.PaymentValue) }}</vs-td>
                    </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>

        </div>
      </vx-card>
    </div>
  </template>
  <script>
    import vSelect from "vue-select";
    import Datepicker from "vuejs-datepicker";
    import moment from "moment";
    import VueNumber from "@/components/vue-number/component.vue";
  
    export default {
      components: {
        Datepicker,
        "v-select": vSelect,
        "vue-number":VueNumber,
      },
      props: {
        selected: Object,
      },
      data() {
        return {
            dataGet: {},
            customerDeposit: {},
            optionMethod: ["Cash", "Giro", "Cheque", "Transfer"],
            detail: {
                customer: "",
                code: "",
                sourceTransaction: "",
                method: "",
                depositNumber: "",
                postingDate: "",
                depositDate: "",
                depositValue: 0,
            },
            allocate: [{
                id: 0,
                reference: "",
                value: 0,
            }]
        }
      },
      computed: {
        totalUsed() {
            let used = 0
            // if (this.dataGet.cashBank.Info != null) {
            //     used = this.dataGet.cashBank.Info.used
                    // .map((v) => parseFloat(v.ValueSettled))
                    // .reduce((acc, curr) => acc + curr, 0);
            // }
            // let allocate = this.totalAllocate.toString().replace(/,/g, "")

            used = this.dataGet.cashBankLine
            .filter(v => v.TransactionType != "allocation")
            .map((v) => parseFloat(v.PaymentValue.toString().replace(/,/g, "")))
            .reduce((acc, curr) => acc + curr, 0);

            return this.formatPrice(parseFloat(used)) ;
        },
        totalAvailable() {
            let used = this.totalUsed.toString().replace(/,/g, "")
            let depoVal = this.customerDeposit.DepositValue
            let allocate = this.totalAllocate.toString().replace(/,/g, "")

            let available = parseFloat(depoVal) - parseFloat(used) - parseFloat(allocate)

            return this.formatPrice(available);
        },
        totalAllocate() {
            let allocate = this.allocate
                .map((v) => parseFloat(v.value.toString().replace(/,/g, "")))
                .reduce((acc, curr) => acc + curr, 0);
            return this.formatPrice(allocate);
        },
      },
      async mounted() {
        console.log(this.selected, "selected data");
        const path = this.$router.currentRoute.path.toLowerCase();
        const id = parseInt(atob(this.$route.params.id));

        await this.getCustomerDeposit(id)
        this.getGeneralForm()
      },
      methods: {
        navigateBack() {
            return this.$router.push({ name: "customer-deposit" });
        },
        async getCustomerDeposit(id) {
            try {
                this.$vs.loading();
                const resp = await this.$http.get(`/api/v1/customer-deposit/${id}`);
                console.log(resp);
                this.dataGet = resp.data;
                this.customerDeposit = resp.data.cashBank;

                this.$vs.loading.close();
            } catch (e) {
                console.log(e);
                this.$vs.loading.close();
            }
        },
        getGeneralForm() {
            this.detail.code = this.customerDeposit.Code
            this.detail.customer = "("+this.customerDeposit.PersonCode+") "+this.customerDeposit.PersonName
            this.detail.sourceTransaction = this.customerDeposit.SourceTransaction
            this.detail.method = this.optionMethod[this.customerDeposit.Method-1]
            this.detail.depositNumber = this.customerDeposit.DepositNumber
            this.detail.postingDate = moment(this.customerDeposit.Date).format(
                "DD MMMM YYYY"
            );
            this.detail.depositDate = moment(this.customerDeposit.DatePayment).format(
                "DD MMMM YYYY"
            );
            this.detail.depositValue = this.formatPrice(this.customerDeposit.DepositValue)

            this.allocate = []
            this.dataGet.cashBankLine.forEach(line => {
                if (line.TransactionType == "allocation") {
                    let reference = ""

                    if (line.Info != null) {
                        reference = line.Info.reference
                    }

                    let dataAllocate = {
                        id: line.ID,
                        reference: reference,
                        value: this.formatPrice(line.PaymentValue),
                    }

                    this.allocate.push(dataAllocate)
                }
            });

            if (this.allocate.length == 0) {
                this.allocate = [{
                id: 0,
                reference: "",
                value: 0,
            }]
            }
        },
        handleSubmit() {
            if (parseFloat(this.totalAvailable.toString().replace(/,/g, "")) < 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Available can't be less than 0",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return
            }
            
            this.$vs.loading();
            
            this.allocate.forEach(v => {
                v.value = parseFloat(v.value.toString().replace(/,/g, ""))
            });

            let param = {
                allocate: this.allocate,
                cashBankID: this.customerDeposit.ID
            }

            this.$http
                .post("/api/v1/cash-bank/customer-deposit-allocate", param)
                .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.$vs.notify({
                    title: "Success",
                    text: "",
                    color: "success",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                    });
                    return this.$router.push({ name: "customer-deposit" });
                } else {
                    this.$vs.notify({
                    title: "Error",
                    text: "Failed to create",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                    });
                }
                })
                .catch((e) => {
                this.$vs.notify({
                    title: "Error",
                    text: "Failed to create",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                });
        },
        addField() {
            this.allocate.push({
                id: 0,
                reference: "",
                value: 0,
            });
        },
        removeField(index) {
            // if (this.allocate.length > 0) {
            //     this.$vs.notify({
            //         title: "Error",
            //         text: "Cannot remove",
            //         color: "danger",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-check",
            //     });
            // }

            this.allocate.splice(index, 1);
            console.log(index, "removeFieldCustDepo");
        },
        formatPrice(angka, prefix = "") {
            let number = parseFloat(angka).toFixed(2);
            return number
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      }
    }
  </script>
<style scoped>
  .margin-btm {
    margin-bottom: 1rem !important;
  }
  
  .vs-input--input.normal {
    text-align: end;
  }
</style>